import React from "react";
import {Typography} from "@mui/material";

export const Content = () => {
    return (
        <article>
            <Typography variant="body1">
                El Dorado Painting located in Cheyenne, WY was established May 1992 making this our 30th year of
                business in the area.  Licensed and insured with an A+ rating with the BBB.
            </Typography>

            <Typography variant="body1">
                Owner operator, Steve Baker and Forman, Scott Dewberry offer several painting services for large and small jobs to include but not limited to
            </Typography>

            <ul>
                <li>New and Existing Interior &amp; Exterior Paint</li>
                <li>Residential, Commercial, and Industrial</li>
                <li>Seal Foundations and Concrete</li>
                <li>Seal Decks and Fences</li>
                <li>Custom Painting
                    <ul>
                        <li>White Ceilings</li>
                        <li>Trim Colors</li>
                        <li>Wall Accents</li>
                        <li>Wall Borders</li>
                        <li>Stain</li>
                    </ul>
                </li>
            </ul>

            <Typography variant="body1">
                Every job we begin by power washing or cleaning all surfaces to be painted or stained.  Once surface is
                cleaned, surfaces are scraped, calked, and sanded to remove any peeling paint and smooth surface.
                Windows and doors are masked off .  Using Sherwin Williams paint we will first prime any areas that will
                require paint and let dry.  Finally, desired paint color is applied.
            </Typography>

            <Typography variant="body1">
                El Dorado Painting offers several options for paint surfaces and application
            </Typography>

            <ul>
                <li>Wood</li>
                <li>Metal</li>
                <li>Vinyl Siding</li>
                <li>Where applicable, Spraying</li>
                <li>Brushing</li>
                <li>Rolling</li>
                <li>Spray Paint</li>
            </ul>

            <Typography variant="body1">
                Some, of our work around the Cheyenne area including,
            </Typography>

            <ul>
                <li>Kum &amp; Go</li>
                <li>Maverick</li>
                <li>Element Church</li>
                <li>Warehouse 21</li>
                <li>Immigration Control Enforcement (ICE) office and building</li>
                <li>Lexington Hills Apartments Complex exterior</li>
            </ul>

            <Typography variant="body1">
                Call or Email for a quote, calls will be returned within 24 hours.
            </Typography>
        </article>
    )
}
