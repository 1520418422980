import React from 'react';
import './App.css';
import {Container, Grid, Paper} from "@mui/material";
import {Content, Footer, Header, RequestQuote} from "./components";

function App() {

    return (
        <Container maxWidth="md">
            <Paper elevation={3}>
                <Grid container spacing={2} p={5}>
                    <Grid item xs={12}>
                        <Header/>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Content />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <aside>
                            <RequestQuote/>
                        </aside>
                    </Grid>
                    <Grid item xs={12}>
                        <Footer/>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default App;
