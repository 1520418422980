import {Grid, Typography} from "@mui/material";
import React from "react";

export const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer>
            <Grid container justifyContent="right">
                <Typography variant="caption">&copy; {year} Eldorado Painting Inc</Typography>
            </Grid>
        </footer>
    )
}