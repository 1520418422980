import React from 'react';
import {Grid, Link, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faYelp} from "@fortawesome/free-brands-svg-icons";

export const Header = () => {
    const headerTheme = { fontSize: { md: '3rem', xs: '2rem' }}
    return (
        <header>
            <Grid container spacing={0} alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="h3" sx={headerTheme}>Eldorado Painting Inc</Typography>
                </Grid>
                <Grid item container direction="row" xs={4} justifyContent="right">
                    <Link href="https://www.facebook.com/Eldorado-Painting-INC-455755068135968/" target="_blank">
                        <FontAwesomeIcon icon={faFacebookSquare} size="2x"/>
                    </Link>
                    <Link href="https://www.yelp.com/biz/eldorado-painting-cheyenne-2" ml={3}>
                        <FontAwesomeIcon icon={faYelp} size="2x"/>
                    </Link>
                </Grid>
            </Grid>
        </header>
    );
}