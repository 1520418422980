import React, {useState} from "react";
import {Alert, Button, CircularProgress, Grid, Stack, TextField, Typography} from "@mui/material";
import axios from "axios";

const initialFormValues: Record<string, string> = {
    name: '',
    phone: '',
    address: '',
    desc: ''
}

const initialFormErrors: Record<string, boolean> = {
    name: true,
    phone: true,
    address: true,
    desc: true
}

const API = 'https://9ly400qt98.execute-api.us-west-1.amazonaws.com/p'

export const RequestQuote = () => {

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [wasSubmitted, setWasSubmitted] = useState(false);
    const [formState, setFormState] = useState(initialFormValues);
    const [formError, setFormErrors] = useState(initialFormErrors);

    const handleFieldUpdate = (e: any) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: value
        });
        setFormErrors({
            ...formError,
            [name]: name === 'phone' && value ? !/^\(?[\d]{3}\)?[\D]?[\d]{3}[\D]?[\d]{4}$/.test(value) : !value
        });
    }

    const validateForm = () => {
        return Object.values(formError).every((v) => !v);
    }

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();

        setShowError(false)
        setShowSuccess(false)

        if (validateForm()) {
            setProcessing(true);

            try {
                await axios.post(`${API}/processContact`, formState)
                setShowSuccess(true);
            } catch (e) {
                setShowError(true)
            }

            setFormState(initialFormValues);
            setFormErrors(initialFormErrors);
            setWasSubmitted(false);
            setProcessing(false);

            e.target.reset();
        } else {
            setWasSubmitted(true);
        }
    }

    const hideSuccess = () => {
        setShowSuccess(false);
    }

    const hideError = () => {
        setShowError(false);
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <Stack spacing={2}>
                <Typography variant="h6">Request A Quote</Typography>
                {showSuccess && <Alert onClose={hideSuccess}>We will contact you soon</Alert>}
                {showError && <Alert severity="error" onClose={hideError}>Something went wrong</Alert>}
                <TextField
                    error={wasSubmitted && formError.name}
                    label="Name"
                    name="name"
                    variant="outlined"
                    onChange={handleFieldUpdate}
                    onBlur={handleFieldUpdate}
                />
                <TextField
                    error={wasSubmitted && formError.phone}
                    type="phone"
                    label="Phone Number"
                    name="phone"
                    variant="outlined"
                    onChange={handleFieldUpdate}
                    onBlur={handleFieldUpdate}
                />
                <TextField
                    error={wasSubmitted && formError.address}
                    label="Property Address"
                    name="address"
                    variant="outlined"
                    onChange={handleFieldUpdate}
                    onBlur={handleFieldUpdate}
                />
                <TextField
                    multiline
                    error={wasSubmitted && formError.desc}
                    rows={4}
                    label="Job Description"
                    name="desc"
                    variant="outlined"
                    onChange={handleFieldUpdate}
                    onBlur={handleFieldUpdate}
                />
                {processing ? <Grid alignSelf="center" item><CircularProgress /></Grid> : <Button type="submit" variant="contained">Submit</Button>}
            </Stack>
        </form>
    )
}